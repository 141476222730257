<template>
<footer>

</footer>
</template>

<script>
export default {
  name: 'SiteFooter',
}
</script>

<style scoped>
</style>