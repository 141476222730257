<template>
  <ul v-if="plantlist">
    <li v-for="item in plantlist" :key="item.plant_ID">
      <PlantButton :plantName="item.plant_name" :data-plantID="item.plant_ID"></PlantButton>
    </li>
  </ul>
</template>

<script>
import PlantButton from './plantButton.vue';

export default {
    name: "PlantList",
    data: function () {
      return {
        plantlist: null
      };
    },
    created: async function () {
      let response = await fetch("./queries/getPlants.php", {
        method: 'GET',
      });
      
      let plants = await response.json();
      this.plantlist = plants;
    },
    components: { PlantButton },
}
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}

li {
  text-align: left;
}
</style>