<template>
  <article class="post" data-postID="{{ postID }}">
    <section class="postContent">{{ postContent }}</section>
    <section class="postStats">Likes: {{ postLikes }}</section>
    <PlantPostComment v-for="comment in postComments" :key="comment.comment_ID" :commentContent="comment.comment_content_text" :commentLikes="comment.comment_likes" :commentPlantID="comment.plant_ID"></PlantPostComment>
  </article>
</template>

<script>
import PlantPostComment from './plantPostComment.vue';

export default {
  name: 'PlantPost',
  props: {
    postContent: String,
    postID: Number,
    postLikes: Number
  },
  data: function () {
    return {
      postComments: null,
    }
  },
  components: {
    PlantPostComment
  },
  mounted: async function () {
      console.log(this);
      const postID = this.$el.attributes[2].value;
      
      // DEBUG
      console.log(postID);

      const helperForm = new FormData();
      helperForm.append('postID', postID);

      const response = await fetch('./queries/getComments.php', {
        method: 'POST',
        body: helperForm,
      });

      const postComments = await response.json();
      this.postComments = postComments;
    }
}
</script>

<style scoped>
.post {
  padding: 1vw;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.postContent {
  background-color: grey;
  color: white;
}

.postStats {
  background-color: white;
  color: darkgrey;
  border: 1px solid black;
}
</style>