<template>
<main>
  <PlantTimeline></PlantTimeline>
</main>
</template>

<script>
import PlantTimeline from './plantTimeline.vue';

export default {
    name: "SiteMain",
    components: { 
      PlantTimeline, 
      },
}
</script>

<style scoped>
main {
  width: 80%;
}
</style>