<template>
<aside>
  <PlantList></PlantList>
</aside>
</template>

<script>
import PlantList from './plantList.vue';

export default {
  name: 'SiteAside',
  components: {
    PlantList,
}
}
</script>

<style scoped>
aside {
  width: 20%;
  background-color: lightgrey;
}
</style>