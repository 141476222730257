<template>
  <header>
    <h1>{{ pageTitle }}</h1>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
  props: {
    pageTitle: String
  }
}
</script>

<style scoped>
header {
  width: 100%;
  background-color: grey;
  color: white;
}
</style>