import Vue from 'vue'
import App from './App.vue'

const timeline = Vue.observable({timeline: null});

Vue.prototype.$timeline = timeline;

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
