<template>
  <button @click="getTimeline" type="button" class="plantButton" data-plantID="{{ plantID }}">
    {{ plantName }}
  </button>
</template>

<script>
export default {
  name: 'PlantButton',
  props: {
    plantName: String,
    plantID: Number
  },
  methods: {
    getTimeline: async function (event) {
      const plantID = event.target.getAttribute("data-plantID");

      const helperForm = new FormData();
      helperForm.append('plantID', plantID);

      const response = await fetch('./queries/getPosts.php', {
        method: 'POST',
        body: helperForm,
      });

      let planttimeline = await response.json();
      this.$timeline.timeline = planttimeline;
    }
  },
}
</script>

<style scoped>
button, button:active {
  background-color: white;
  color: grey;
  border: 1px solid grey;
  margin: 0.25rem 0;
}

button:hover {
  background-color: grey;
  color: white;
}
</style>