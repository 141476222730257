<template>
<div>
  <PlantPost v-for="post in $timeline.timeline" :key="post.post_ID" :data-postID="post.post_ID" :postContent="post.post_content_text" :postLikes="post.post_likes"></PlantPost>
</div>
</template>

<script>
import PlantPost from './plantPost.vue';

export default {
  name: 'PlantTimeline',
  components: {
    PlantPost
    },
}
</script>

<style scoped>
</style>