<template>
  <section class="postComment">
    <p class="postCommentContent">
      {{ commentContent }}
    </p>
    <p class="postCommentStats">
      Likes: {{ commentLikes }}
    </p>
    <p class="postCommentStats">
      Plant: {{ commentPlantID }}
    </p>
  </section>
</template>

<script>
export default {
  name: 'PlantPostComment',
  props: {
    commentContent: String,
    commentLikes: Number,
    commentPlantID: Number
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}

.postComment {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 1vw 0 0 5vw;
}

.postCommentContent {
  background-color: lightgray;
  color: slategray;
  border: 1px solid slategray;
  flex: 1 0 100%;
}

.postCommentStats {
  background-color: slategray;
  color: white;
  border: 1px dashed black;
  flex: 2 0 25%;
}
</style>