<template>
  <div id="app">
    <SiteHeader pageTitle="wwwhouse - Testumgebung"></SiteHeader>
    <SiteAside></SiteAside>
    <SiteMain></SiteMain>
    <SiteFooter></SiteFooter>
  </div>
</template>

<script>
import SiteHeader from './components/header.vue';
import SiteAside from './components/aside.vue';
import SiteMain from './components/main.vue';
import SiteFooter from './components/footer.vue';
//import HelloWorld from './components/HelloWorld.vue'

export default {
    name: "App",
    components: {
      SiteHeader,
      SiteAside,
      SiteMain,
      SiteFooter
      },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: darkgrey;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
</style>
